var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t("default"),
      _vm.SefosDTO
        ? _c(
            "b-sidebar",
            {
              attrs: {
                "sidebar-class": "big_sidebar create-sefos-message",
                right: "",
                title: _vm.GetTitle,
                backdrop: "",
                "no-slide": "",
                lazy: "",
                shadow: "",
                "no-header-close": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { shown: _vm.shownSidebar, hidden: _vm.closeBtn },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function ({ hide }) {
                      return [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.step == 1,
                                expression: "step == 1",
                              },
                            ],
                            staticClass: "ml-2",
                          },
                          [
                            _c("b-button", { on: { click: _vm.closeBtn } }, [
                              _vm._v(_vm._s(_vm.$t("CLOSE"))),
                            ]),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.saving || _vm.disabledSave,
                                  variant: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(true)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
                            ),
                            _vm.SefosDTO.response_to_message_uuid == ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.disabledGotoStep2,
                                      variant: "primary",
                                    },
                                    on: { click: _vm.gotoStep2 },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                )
                              : _vm._e(),
                            _vm.SefosDTO.response_to_message_uuid != "" &&
                            _vm.SefosDTO.external_participants.length != 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.disabledGotoStep2,
                                      variant: "primary",
                                    },
                                    on: { click: _vm.gotoStep2 },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                )
                              : _vm._e(),
                            _vm.SefosDTO.response_to_message_uuid != "" &&
                            _vm.SefosDTO.external_participants.length == 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.disabledGotoStep2,
                                      variant: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveMessage(false)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                                )
                              : _vm._e(),
                            _vm.SefosDTO.message_uuid != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill float-right",
                                    attrs: {
                                      disabled: _vm.saving,
                                      variant: "danger",
                                    },
                                    on: { click: _vm.RemoveDraft },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.step == 2,
                                expression: "step == 2",
                              },
                            ],
                            staticClass: "ml-2",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.step = 1
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("BACK")))]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.saving || _vm.disabledSave,
                                  variant: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(true)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
                            ),
                            _vm.SefosDTO.external_participants.length != 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: { variant: "primary" },
                                    on: { click: _vm.gotoStep3 },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                )
                              : _vm._e(),
                            _vm.SefosDTO.external_participants.length == 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: { variant: "primary" },
                                    on: { click: _vm.gotoStep3 },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                                )
                              : _vm._e(),
                            _vm.SefosDTO.message_uuid != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill float-right",
                                    attrs: {
                                      disabled: _vm.saving,
                                      variant: "danger",
                                    },
                                    on: { click: _vm.RemoveDraft },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.step == 3,
                                expression: "step == 3",
                              },
                            ],
                            staticClass: "ml-2",
                          },
                          [
                            _c("b-button", { on: { click: _vm.goBackStep2 } }, [
                              _vm._v(_vm._s(_vm.$t("BACK"))),
                            ]),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.saving || _vm.disabledSave,
                                  variant: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(true)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.disabledSendMessage,
                                  variant: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(false)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                            ),
                            _vm.SefosDTO.message_uuid != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill float-right",
                                    attrs: {
                                      disabled: _vm.saving,
                                      variant: "danger",
                                    },
                                    on: { click: _vm.RemoveDraft },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                189408161
              ),
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: !_vm.SefosDTO.isDraft && _vm.saving,
                    rounded: "sm",
                  },
                },
                [
                  _vm.settings && _vm.loaded
                    ? _c(
                        "div",
                        {
                          staticClass: "view-content create-message px-3 py-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step == 1,
                                  expression: "step == 1",
                                },
                              ],
                            },
                            [
                              _vm.SefosDTO.response_to_message_uuid == ""
                                ? _c("SelectFrom", {
                                    attrs: {
                                      functionbox_uuid:
                                        _vm.SefosDTO.functionbox_uuid,
                                      user_uuid: _vm.SefosDTO.user_uuid,
                                    },
                                    model: {
                                      value: _vm.selected_from,
                                      callback: function ($$v) {
                                        _vm.selected_from = $$v
                                      },
                                      expression: "selected_from",
                                    },
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "info-header mt-2" }, [
                                _vm._v(_vm._s(_vm.$t("ADD_RECIPIENTS"))),
                              ]),
                              _c("InputParticipants", {
                                key:
                                  "InputParticipants_" +
                                  _vm.externalParticipantsKey,
                                attrs: {
                                  message_uuid: _vm.SefosDTO.message_uuid,
                                  functionbox_uuid:
                                    _vm.SefosDTO.functionbox_uuid,
                                  user_uuid: _vm.SefosDTO.user_uuid,
                                  message_type: "message",
                                  participants: _vm.SefosDTO.participants,
                                  external_participants:
                                    _vm.SefosDTO.external_participants,
                                },
                                on: {
                                  IsTyping: _vm.IsTyping,
                                  UpdatedParticipants: _vm.UpdatedParticipants,
                                },
                              }),
                              _c("div", { staticClass: "info-header mt-2" }, [
                                _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                              ]),
                              _c("InputSubject", {
                                attrs: { settings: _vm.settings, limit: 256 },
                                on: {
                                  valid: (n) => (_vm.validatedSubject = n),
                                },
                                model: {
                                  value: _vm.SefosDTO.subject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.SefosDTO, "subject", $$v)
                                  },
                                  expression: "SefosDTO.subject",
                                },
                              }),
                              _c("InputBody", {
                                attrs: { settings: _vm.settings, limit: 20000 },
                                on: { valid: (n) => (_vm.validatedBody = n) },
                                model: {
                                  value: _vm.SefosDTO.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.SefosDTO, "body", $$v)
                                  },
                                  expression: "SefosDTO.body",
                                },
                              }),
                              _c("div", { staticClass: "info-header mt-2" }, [
                                _vm._v(_vm._s(_vm.$t("ADD_FILES"))),
                              ]),
                              _c(
                                "div",
                                { key: "AddFiles" + _vm.SefosDTO.message_uuid },
                                [
                                  _c("InputFiles", {
                                    attrs: {
                                      message_uuid: _vm.SefosDTO.message_uuid,
                                      functionbox_uuid:
                                        _vm.SefosDTO.functionbox_uuid,
                                      user_uuid: _vm.SefosDTO.user_uuid,
                                      "cannot-upload":
                                        _vm.$t("DRAFT.SEFOS_UPLOAD"),
                                      "max-size": 200,
                                    },
                                    on: {
                                      valid: (n) => (_vm.validatedFiles = n),
                                    },
                                    model: {
                                      value: _vm.SefosDTO.attachments,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SefosDTO,
                                          "attachments",
                                          $$v
                                        )
                                      },
                                      expression: "SefosDTO.attachments",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step == 2,
                                  expression: "step == 2",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "info-header mt-2" }, [
                                _vm._v(_vm._s(_vm.$t("MESSAGES.LOA_LEVELS"))),
                              ]),
                              _c("InputLoa", {
                                model: {
                                  value: _vm.SefosDTO.settings.loa_level,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.SefosDTO.settings,
                                      "loa_level",
                                      $$v
                                    )
                                  },
                                  expression: "SefosDTO.settings.loa_level",
                                },
                              }),
                              _vm.settings.reply
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "info-header mt-2" },
                                      [_vm._v(_vm._s(_vm.$t("RESPONSE")))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "require_response",
                                              value: 1,
                                              switch: "",
                                              "unchecked-value": 0,
                                            },
                                            model: {
                                              value:
                                                _vm.SefosDTO.settings
                                                  .require_response,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.SefosDTO.settings,
                                                  "require_response",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "SefosDTO.settings.require_response",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "MESSAGES.REQUEST_RESPONSE"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.step == 3
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "info-header mt-2" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "MESSAGES.EXTERNAL_RECIPIENTS"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("MESSAGES.NOT_REGISTERED")
                                        ) +
                                        "\n          "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "MESSAGES.NOT_REGISTERED_INFORMATION"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      key:
                                        "NotRegistedLoa_" +
                                        _vm.SefosDTO.settings.loa_level +
                                        "_" +
                                        _vm.SefosDTO.external_participants,
                                      staticClass: "mt-2",
                                    },
                                    _vm._l(
                                      _vm.SefosDTO.external_participants,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: "NotRegistered_" + index,
                                            staticClass: "NotRegistered",
                                          },
                                          [
                                            _c("NotRegistered", {
                                              attrs: {
                                                message_type: "message",
                                                user_uuid:
                                                  _vm.SefosDTO.user_uuid,
                                                functionbox_uuid:
                                                  _vm.SefosDTO.functionbox_uuid,
                                                methods: _vm.methods,
                                                loa_level:
                                                  _vm.SefosDTO.settings
                                                    .loa_level,
                                              },
                                              on: {
                                                change:
                                                  _vm.UpdatedNotRegistered,
                                                remove: function ($event) {
                                                  return _vm.RemoveNotRegistered(
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.SefosDTO
                                                    .external_participants[
                                                    index
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.SefosDTO
                                                      .external_participants,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "SefosDTO.external_participants[index]",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "secondary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 1
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("ADD")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "info-header mt-2" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t("MESSAGES.EXTERNAL_TEXT")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c("InputExternalText", {
                                    attrs: {
                                      settings: _vm.settings,
                                      limit: 1500,
                                    },
                                    on: {
                                      valid: (n) =>
                                        (_vm.validatedExternalText = n),
                                    },
                                    model: {
                                      value: _vm.SefosDTO.external_text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.SefosDTO,
                                          "external_text",
                                          $$v
                                        )
                                      },
                                      expression: "SefosDTO.external_text",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
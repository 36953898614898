<template>
  <span
    ><slot></slot>
    <b-sidebar
      id="add-sdk-message"
      sidebar-class="big_sidebar create-sdk-message"
      :title="GetTitle"
      v-model="show"
      right
      backdrop
      shadow
      no-slide
      lazy
      no-close-on-backdrop
      no-close-on-esc
      no-header-close
      @shown="shownSidebar"
      @hidden="closeBtn"
      v-if="CreateSdkMessageDTO"
    >
      <template #footer="{ hide }">
        <div v-if="step == 1" class="ml-2">
          <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >

          <b-button @click="step = 3" variant="primary" class="btn-fill">{{
            $t("NEXT")
          }}</b-button>

          <b-button
            v-if="CreateSdkMessageDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>

        <div v-if="step == 3" class="ml-2">
          <b-button @click="goBackStep1">{{ $t("BACK") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >

          <b-button
            :disabled="disabledPresendMessage"
            @click="preSendMessage"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            v-if="CreateSdkMessageDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>

        <div v-if="step == 2" class="ml-2">
          <b-button @click="step = 3">{{ $t("CLOSE") }}</b-button>
        </div>

        <div v-if="step == 4 || step == 5" class="ml-2">
          <b-button @click="step = 1">{{ $t("CLOSE") }}</b-button>
        </div>
      </template>

      <b-overlay :show="!CreateSdkMessageDTO.isDraft && saving" rounded="sm">
        <div
          v-if="settings && loaded"
          class="view-content compose-content create-message px-3 py-2 pb-5"
        >
          <div v-if="step == 1 || step == 3">
            <b-alert
              v-if="settings.environment == 'QA'"
              show
              variant="warning"
              class="pt-2 pb-2"
            >
              <div v-html="$t('SDK.ENVIRONMENT.QA_NOTIFICATION')"></div>
            </b-alert>
            <b-alert
              v-if="settings.environment == 'OPEN_TEST'"
              show
              variant="warning"
              class="pt-2 pb-2"
            >
              <div v-html="$t('SDK.ENVIRONMENT.OPEN_TEST_NOTIFICATION')"></div>
            </b-alert>
          </div>

          <div v-if="step == 1">
            <SelectFrom
              v-if="CreateSdkMessageDTO.response_to_message_uuid == ''"
              sdk-message
              v-model="selected_from"
              :functionbox_uuid="functionbox_uuid"
              :user_uuid="user_uuid"
            ></SelectFrom>

            <div class="info-header mt-2">{{ $t("TO") }}</div>
            <div v-if="!to_sdk" class="mb-2">{{ $t("NONE_SELECTED") }}</div>
            <div v-if="to_sdk" class="mb-2">
              <div class="sdk-recipient">
                {{ to_sdk.attributes.name }},
                {{ to_sdk.relationships.parent.data.name }}
                <div
                  v-if="to_sdk.attributes.name != to_sdk.attributes.description"
                >
                  <small>{{ to_sdk.attributes.description }}</small>
                </div>
              </div>
            </div>
            <div>
              <b-button
                size="sm"
                class="mt-1"
                variant="primary"
                @click="addSdkRecipient"
              >
                <i class="fal fa-bookmark"></i>
                {{ $t("SDK.SELECT_RECIPIENT") }}
              </b-button>
              <b-button
                class="mt-1"
                size="sm"
                variant="primary"
                @click="addNewSdkParticipant"
              >
                <i class="fal fa-search"></i>
                {{ $t("SDK.SELECT_FROM_ADRESSBOOK") }}
              </b-button>
              <b-button
                class="mt-1"
                size="sm"
                variant="primary"
                @click="addWithIdentifier = true"
              >
                <i class="fal fa-plus"></i>
                {{ $t("SDK.ADD_WITH_IDENTIIER") }}
              </b-button>
              <b-button
                class="mt-1"
                size="sm"
                variant="secondary"
                v-if="to_sdk"
                @click="to_sdk = null"
              >
                <i class="fal fa-trash-alt"></i>
                {{ $t("CLEAR") }}
              </b-button>
            </div>

            <b-modal
              id="add-sdk-with-identiier"
              v-model="addWithIdentifier"
              hide-header
              ok-only
              @ok="closeAddIdentifier"
              centered
              :dialog-class="teams.theme"
              :ok-title="$t('CANCEL')"
            >
              <b-form-input
                trim
                v-model="search_identifier"
                :placeholder="$t('SDK.IDENTIFIER')"
              ></b-form-input>
              <b-form-input
                trim
                class="mt-2"
                v-model="search_organisation"
                :placeholder="$t('ORGANISATION.TITLE')"
              ></b-form-input>
              <b-button
                class="mt-2"
                @click="searchIdentifier"
                :disabled="search_identifier == ''"
                variant="primary"
                >{{ $t("FETCH_INFORMATION") }}</b-button
              >

              <b-table
                v-if="searchItems.length != 0"
                class="mt-2"
                striped
                hover
                :items="searchItems"
                :fields="searchFields"
              >
                <template v-slot:cell(Type)="data">
                  {{ data.item.attributes.name }},
                  {{ data.item.relationships.parent.data.name }}
                  <br />
                  <b-button
                    variant="dark"
                    size="sm"
                    @click="useAddress(data.item)"
                    >{{ $t("USE") }}</b-button
                  >
                </template>

                <template v-slot:cell(id)="data">
                  <b-button
                    variant="dark"
                    size="sm"
                    @click="useAddress(data.item)"
                    >{{ $t("USE") }}</b-button
                  >
                </template>
              </b-table>

              <div v-if="searchDone && searchItems.length == 0">
                {{ $t("SDK.NOT_FOUND_SEARCH") }}
              </div>
            </b-modal>

            <div class="info-header mt-2">{{ $t("INFORMATION") }}</div>

            <InputSubject
              v-model="CreateSdkMessageDTO.subject"
              :settings="settings"
              @valid="(n) => (validatedSubject = n)"
              :limit="256"
            ></InputSubject>

            <InputBody
              v-model="CreateSdkMessageDTO.body"
              :settings="settings"
              @valid="(n) => (validatedBody = n)"
              :limit="20000"
            ></InputBody>

            <b-form-checkbox
              class="mt-2"
              id="checkbox-isconfidential"
              v-model="CreateSdkMessageDTO.isconfidential"
              name="checkbox-isconfidential"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t("SDK.IsConfidential") }}
            </b-form-checkbox>

            <div v-if="CreateSdkMessageDTO.isconfidential">
              <b-form-radio-group
                v-model="CreateSdkMessageDTO.confidentialLevel"
                :options="confidentialOptions"
                stacked
              ></b-form-radio-group>
            </div>
          </div>
          <div v-show="step == 3">
            <div class="info-header mt-2">{{ $t("ADD_FILES") }}</div>

            <div
              :show="CreateSdkMessageDTO.message_uuid == ''"
              rounded="sm"
              :key="'AddFiles' + CreateSdkMessageDTO.message_uuid"
            >
              <InputFiles
                :message_uuid="CreateSdkMessageDTO.message_uuid"
                :functionbox_uuid="CreateSdkMessageDTO.functionbox_uuid"
                :user_uuid="CreateSdkMessageDTO.user_uuid"
                v-model="CreateSdkMessageDTO.attachments"
                :max-size="30"
                @valid="(n) => (validatedFiles = n)"
                total-size
                :cannot-upload="$t('DRAFT.SDK_UPLOAD')"
              ></InputFiles>
            </div>

            <div class="info-header mt-2">
              {{ $t("SDK.EXTRA_INFORMATION") }}
            </div>

            <b-button
              size="sm"
              variant="secondary"
              @click="step = 2"
              class="no-border"
            >
              <i class="fal fa-plus"></i>&nbsp;
              <span v-if="metadataItemsCount == 0">{{
                $t("SDK.ADD_EXTRA_INFO_EMPTY")
              }}</span>
              <span v-if="metadataItemsCount != 0">{{
                $t("SDK.ADD_EXTRA_INFO", { "0": metadataItemsCount })
              }}</span>
            </b-button>
          </div>

          <SDKReferences
            v-if="step == 2"
            :key="referencesKey"
            @cancel="step = 3"
            :fromParticipantPersons="CreateSdkMessageDTO.fromParticipantPersons"
            :fromParticipantReferences="
              CreateSdkMessageDTO.fromParticipantReferences
            "
            :toParticipantPersons="CreateSdkMessageDTO.toParticipantPersons"
            :toParticipantReferences="
              CreateSdkMessageDTO.toParticipantReferences
            "
            @ok="setReferences"
          ></SDKReferences>

          <SDKSearch
            v-if="step == 5"
            @cancel="step = 1"
            @selectedAddress="selectedAddress"
            :functionbox_uuid="CreateSdkMessageDTO.functionbox_uuid"
          ></SDKSearch>

          <SDKRecipients
            v-if="step == 4"
            @cancel="step = 1"
            @selectedAddress="selectedAddress"
            :functionbox_uuid="CreateSdkMessageDTO.functionbox_uuid"
          ></SDKRecipients>
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
import InputSubject from "../../Input/Subject";
import InputBody from "../../Input/Body";
import InputFiles from "../../Input/Files";
import SDKSearch from "../../Input/SDK/SdkSearch";
import SDKReferences from "../../Input/SDK/SdkReferences";
import SDKRecipients from "../../Input/SDK/SdkRecipients";
import SelectFrom from "../SelectFrom";
export default {
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    isReply: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isComplete: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isForward: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isReplyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    attachments: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    SDKReferences,
    SDKSearch,
    SDKRecipients,
    InputSubject,
    InputBody,
    InputFiles,
    SelectFrom,
  },
  data() {
    return {
      confidential: 3,
      confidentialOptions: [
        { value: 3, text: this.$t("SDK.IsConfidential3") },
        { value: 1, text: this.$t("SDK.IsConfidential1") },
        { value: 2, text: this.$t("SDK.IsConfidential2") },
      ],
      validatedSubject: true,
      validatedBody: true,
      validatedFiles: true,
      search_identifier: "",
      search_organisation: "",
      searchDone: false,
      searchItems: [],
      addWithIdentifier: false,
      to_sdk: null,
      loaded: false,
      saving: false,
      timeout: 2000,
      folder_uuid: "",
      canSave: false,
      changedContent: false,
      forceRedrawRecipientsIndex: 0,
      selected_from: {
        functionbox_uuid: this.functionbox_uuid,
        user_uuid: this.user_uuid,
      },
      CreateSdkMessageDTO: {
        user_uuid: this.user_uuid,
        functionbox_uuid: this.functionbox_uuid,
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 3,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
        },
        confidentialLevel: 3,
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
        isconfidential: false,
        toParticipantPersons: [],
        toParticipantReferences: [],
        fromParticipantPersons: [],
        fromParticipantReferences: [],
      },
      step: 1,
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      methods: [],
      loading: false,
      searchFields: [
        {
          key: "attributes.name",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "relationships.parent.data.name",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "Id",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "w-300",
        },
        {
          key: "Type",
          label: "",
          class: "text-break clickable hidden-not-mobile",
          thClass: "w-300 hidden-mobile",
        },
      ],
      fields: [
        {
          thStyle: "width: 100%;",
          key: "authentication_identifier",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          thStyle: "width: 45%;",
          key: "email",
          class: "hidden-mobile",
          label: this.$t("EMAIL"),
          sortable: false,
        },
        {
          thStyle: "width: 45%;",
          key: "authentication_method",
          class: "hidden-mobile",
          label: this.$t("AUTH_METHODS.VERIFY"),
          sortable: false,
        },
        {
          thStyle: "width: 10%;",
          key: "language",
          class: "hidden-mobile",
          label: this.$t("LANGUAGE.TITLE"),
          sortable: false,
        },
      ],
      settings: null,
      timer: null,
    };
  },
  methods: {
    async useAddress(item) {
      this.to_sdk = item;
      this.closeAddIdentifier();
    },
    async searchIdentifier() {
      let SdkIdentifierDTO = {
        functionbox_uuid: this.CreateSdkMessageDTO.functionbox_uuid,
        identifier: this.search_identifier,
        organisation: this.search_organisation,
        name: this.search_organisation,
      };
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sdk/address/identifier",
          SdkIdentifierDTO
        );
        this.searchItems = result.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    closeAddIdentifier() {
      this.loading = false;
      this.identifier = "";
      this.searchDone = false;
      this.search_identifier = "";
      this.search_organisation = "";
      this.searchItems = [];
      this.addWithIdentifier = false;
    },
    setReferences(obj) {
      this.fromParticipantPersons = obj.fromParticipantPersons;
      this.fromParticipantReferences = obj.fromParticipantReferences;
      this.toParticipantPersons = obj.toParticipantPersons;
      this.toParticipantReferences = obj.toParticipantReferences;
      this.step = 1;
      this.showReferences = false;
      this.referencesIndex++;
    },
    cancelReferences() {
      this.step = 1;
    },
    backBtn() {
      this.step = 1;
    },
    selectedAddress(item) {
      this.to_sdk = item;
      this.step = 1;
    },
    addNewSdkParticipant() {
      this.step = 5;
    },
    addSdkRecipient() {
      this.step = 4;
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      this.settings = await this.GetSettings();
      if (
        this.isReply ||
        this.isForward ||
        this.isReplyAll ||
        this.isComplete
      ) {
        await this.InitMessage();
      } else {
        if (this.message_uuid != "") {
          await this.GetInformation();
        } else {
          this.InitSettings();
        }
      }
    },
    InitSettings() {
      this.CreateSdkMessageDTO.attachments = this.attachments;
      if (this.currentSignature != "") {
        this.CreateSdkMessageDTO.body =
          "\n\n----------------------------\n" + this.currentSignature;
      }
      this.InitDone();
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.CreateSdkMessageDTO.action = "NEW";
    },
    async InitMessage() {
      if (this.isReply) {
        this.CreateSdkMessageDTO.action = "REPLY";
      } else if (this.isReplyAll) {
        this.CreateSdkMessageDTO.action = "REPLYALL";
      } else if (this.isForward) {
        this.CreateSdkMessageDTO.action = "FORWARD";
      } else if (this.isComplete) {
        this.CreateSdkMessageDTO.action = "COMPLETE";
      }
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sdk/init/secure",
          this.CreateSdkMessageDTO
        );
        this.CreateSdkMessageDTO = result.data;
        this.GetRecipient();
        this.InitDone();
      } catch {
        console.log("Could not fetch InitMessage");
      }
    },
    async GetRecipient() {
      let SdkIdentifierDTO = {
        functionbox_uuid: this.CreateSdkMessageDTO.functionbox_uuid,
        identifier: this.CreateSdkMessageDTO.to_sdk_identifier,
        organisation: this.CreateSdkMessageDTO.to_sdk_organisation,
        name: "",
      };
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sdk/address/identifier",
          SdkIdentifierDTO
        );
        if (result.data.length == 1) {
          this.to_sdk = result.data[0];
          this.CreateSdkMessageDTO.to_sdk_identifier = "";
          this.CreateSdkMessageDTO.to_sdk_organisation = "";
        }
        // eslint-disable-next-line
      } catch {}
      this.InitDone();
    },
    async GetNewAttachments(message_uuid) {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: message_uuid,
            functionbox_uuid: this.CreateSdkMessageDTO.functionbox_uuid,
            user_uuid: this.CreateSdkMessageDTO.user_uuid,
          }
        );
        this.CreateSdkMessageDTO.attachments =
          result.data.createSdkMessageDTO.attachments;
      } catch {
        console.log("Could not fetch GetNewAttachments");
      }
    },
    async GetInformation() {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.CreateSdkMessageDTO.functionbox_uuid,
            user_uuid: this.CreateSdkMessageDTO.user_uuid,
          }
        );
        this.CreateSdkMessageDTO = result.data.createSdkMessageDTO;
        if (this.CreateSdkMessageDTO.to_sdk_identifier) {
          this.GetRecipient();
        } else {
          this.InitDone();
        }
      } catch {
        console.log("Could not fetch GetInformation");
      }
    },
    async GetSettings() {
      let result = await this.$http.post(this.user.hostname + "/settings/get", {
        setting: "SdkMessage",
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        functionbox_uuid: this.functionbox_uuid,
      });
      return result.data;
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/sefos-message/secure/remove", {
                message_uuid: self.CreateSdkMessageDTO.message_uuid,
                user_uuid: self.user_uuid,
                functionbox_uuid: self.functionbox_uuid,
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        }) // eslint-disable-next-line
        .catch(function () {});
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
      //this.$emit("sentMessage");
    },
    async showReferences() {
      this.step = 2;
    },
    async closeReferences() {
      this.step = 1;
    },
    async goBackStep1() {
      this.step = 1;
    },
    async goBackStep2() {
      this.step = 2;
    },
    async preSendMessage() {
      this.disabledSend = true;
      this.loading = true;
      if (this.settings.environment == "PRODUCTION") {
        await this.saveMessage(false);
      } else {
        await this.preAcceptSendMessage();
      }
    },
    async preAcceptSendMessage() {
      let self = this;
      let message = this.$t("SDK.ENVIRONMENT.QA_NOTIFICATION");
      if (this.settings.environment == "OPEN_TEST") {
        message = this.$t("SDK.ENVIRONMENT.OPEN_TEST_NOTIFICATION");
      }
      message = message.replaceAll("<b>", "");
      message = message.replaceAll("</b>", "");
      this.$bvModal
        .msgBoxConfirm(message, {
          size: "sm",
          title: this.$t("SDK.ENVIRONMENT.TEST"),
          buttonSize: "sm",
          headerBgVariant: "danger",
          okVariant: "primary",
          okTitle: this.$t("SUBMIT"),
          cancelTitle: this.$t("CANCEL"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: true,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.saveMessage(false);
          } else {
            self.loading = false;
          }
        })
        .catch(function () {
          self.loading = false;
        });
    },
    debounceSave() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage(true);
      }, this.timeout);
    },
    async saveMessage(isDraft) {
      if (
        this.saving ||
        this.step == 2 ||
        this.step == 4 ||
        this.step == 5 ||
        this.disabledSave
      ) {
        return;
      }
      this.loading = true;
      clearTimeout(this.timer);
      this.saving = true;
      this.CreateSdkMessageDTO.isDraft = isDraft;
      if (this.external_text == "") {
        this.external_text = this.$t("MESSAGES.EXTERNAL_TEXT_PLACEHOLDER");
      }
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sdk/secure",
          this.CreateSdkMessageDTO
        );
        this.loading = false;
        if (isDraft) {
          this.saving = false;
          if (this.CreateSdkMessageDTO.message_uuid == "") {
            let checkNotUploaded =
              this.CreateSdkMessageDTO.attachments.filter(function (item) {
                return item.status == "NOT_UPLOADED";
              }).length != 0;
            if (this.attachments.length != 0 || checkNotUploaded) {
              await this.GetNewAttachments(result.data.message_uuid);
              this.CreateSdkMessageDTO.message_uuid = result.data.message_uuid;
            } else {
              this.CreateSdkMessageDTO.message_uuid = result.data.message_uuid;
            }
          }
        } else {
          this.CreateSdkMessageDTO.message_uuid = result.data.message_uuid;
          this.loading = false;
          this.$noty.info(this.$t("SENT_MESSAGE"));
          this.$emit("SentMessage");
          this.$emit("ChangeShow", false);
        }
      } catch (error) {
        this.saving = false;
        this.loading = false;
        clearTimeout(this.timer);
      }
    },
  },
  computed: {
    currentSignature() {
      let self = this;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == self.functionbox_uuid
        );
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == self.user_uuid
          );
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
    metadataItemsCount() {
      return (
        (this.CreateSdkMessageDTO.fromParticipantPersons != null
          ? this.CreateSdkMessageDTO.fromParticipantPersons.length
          : 0) +
        (this.CreateSdkMessageDTO.fromParticipantReferences != null
          ? this.CreateSdkMessageDTO.fromParticipantReferences.length
          : 0) +
        (this.CreateSdkMessageDTO.toParticipantPersons != null
          ? this.CreateSdkMessageDTO.toParticipantPersons.length
          : 0) +
        (this.CreateSdkMessageDTO.toParticipantReferences != null
          ? this.CreateSdkMessageDTO.toParticipantReferences.length
          : 0)
      );
    },
    referencesKey() {
      return "references_" + this.referencesIndex;
    },
    disabledPresendMessage: function () {
      if (!this.validatedFiles) {
        return true;
      }
      if (this.to_sdk == null) {
        return true;
      }
      if (this.subject == "") {
        return true;
      }
      return false;
    },
    GetTitle() {
      if (this.saving) {
        if (this.CreateSdkMessageDTO.isDraft) {
          return (
            this.$t("MESSAGES.TYPE.sdk-message") +
            " (" +
            this.$t("SAVING") +
            " .. )"
          );
        } else {
          return (
            this.$t("MESSAGES.TYPE.sdk-message") +
            " (" +
            this.$t("SENDING") +
            " .. )"
          );
        }
      }
      return this.$t("MESSAGES.TYPE.sdk-message");
    },
    disabledSave() {
      return (
        !this.validatedBody || !this.validatedSubject || !this.validatedFiles
      );
    },
  },
  watch: {
    selected_from: function () {
      this.CreateSdkMessageDTO.user_uuid = this.selected_from.user_uuid;
      this.CreateSdkMessageDTO.functionbox_uuid =
        this.selected_from.functionbox_uuid;
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    to_sdk: function (newVal) {
      if (newVal != null) {
        this.CreateSdkMessageDTO.to_sdk_identifier =
          this.to_sdk.attributes.identifier;
        this.CreateSdkMessageDTO.to_sdk_organisation =
          this.to_sdk.relationships.parent.data.identifier;
        if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
          this.debounceSave();
        }
      }
    },
    "CreateSdkMessageDTO.subject": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.body": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.to_sdk_identifier": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.isconfidential": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.confidentialLevel": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.to_sdk_organisation": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.toParticipantPersons": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.toParticipantReferences": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.fromParticipantPersons": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateSdkMessageDTO.fromParticipantReferences": function () {
      if (this.CreateSdkMessageDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
  },
  mounted() {},
};
</script>
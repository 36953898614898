var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFrom
    ? _c(
        "section",
        [
          _c("div", { staticClass: "info-header mt-2" }, [
            _vm._v(_vm._s(_vm.$t("FROM"))),
          ]),
          _c("b-form-select", {
            staticClass: "mt-2",
            attrs: {
              name: "sefos_message_select_from",
              options: _vm.from_list,
            },
            on: { change: _vm.ChangedSelectedFrom },
            model: {
              value: _vm.selected_from,
              callback: function ($$v) {
                _vm.selected_from = $$v
              },
              expression: "selected_from",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.type
    ? _c(
        "span",
        [
          _vm.settings.enabled && _vm.showBtn
            ? _vm._t("activator", null, { onClick: _vm.onClick })
            : _vm._e(),
          !_vm.isCompose && _vm.showAddressbook
            ? _c(
                "b-sidebar",
                {
                  attrs: {
                    "sidebar-class": "big_sidebar",
                    visible: "",
                    right: "",
                    title: _vm.$t("ADDRESSBOOK.TITLE"),
                    backdrop: "",
                    "no-slide": "",
                    lazy: "",
                    shadow: "",
                    "no-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "view-content px-3 py-2" },
                    [
                      _c("List", {
                        key: _vm.updateItemKey,
                        attrs: {
                          settings: _vm.settings,
                          list: _vm.list,
                          functionbox_uuid: _vm.functionbox_uuid,
                          user_uuid: _vm.user_uuid,
                          message_type: _vm.message_type,
                          organisation_uuid: _vm.organisation_uuid,
                          isCompose: _vm.isCompose,
                        },
                        on: { selected: _vm.selectedItems },
                      }),
                      _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "ml-2",
                          attrs: { title: _vm.$t("CLOSE") },
                          on: { click: _vm.closeBtn },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.$t("CLOSE")) + "\n      "
                          ),
                        ]
                      ),
                      _vm.selected.length != 0 && !_vm.isCompose
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2 float-right",
                              attrs: { variant: "danger" },
                              on: { click: _vm.removeItems },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("REMOVE")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isCompose && _vm.showAddressbook
            ? _c(
                "b-modal",
                {
                  attrs: {
                    "dialog-class": _vm.teams.theme,
                    size: "lg",
                    "hide-header": "",
                    "no-close-on-backdrop": "",
                    visible: "",
                    "ok-disabled": _vm.selectDisabled,
                    centered: "",
                    "cancel-title": _vm.$t("CANCEL"),
                    "ok-title": _vm.$t("SELECT"),
                  },
                  on: {
                    hidden: _vm.closeBtn,
                    cancel: _vm.closeBtn,
                    ok: _vm.selectBtn,
                  },
                },
                [
                  _c("List", {
                    key: _vm.updateItemKey,
                    attrs: {
                      settings: _vm.settings,
                      "single-select": _vm.singleSelect,
                      "is-number": _vm.isNumber,
                      list: _vm.list,
                      functionbox_uuid: _vm.functionbox_uuid,
                      user_uuid: _vm.user_uuid,
                      message_type: _vm.message_type,
                      organisation_uuid: _vm.organisation_uuid,
                      isCompose: _vm.isCompose,
                    },
                    on: { selected: _vm.selectedItems },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }